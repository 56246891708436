import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import styles from "./../work.module.sass"


const MethodsAndProcesses = () => (
  <Layout>
    {/*---- Work Detail ----*/}
    <SEO title="Methods and Processes" />

    <article className={styles.workDetail}>
      <Image fileName={"methodsAndProcesses/main.jpg"} />
      <header className={styles.workDetail_header}>
        <h1 className={styles.workDetail_title}>Methods and Processes</h1>
        <h2 className={styles.workDetail_subTitle}>Designing creative framework while developing interventional design for fuel poverty</h2>
        <ul className={styles.workDetail_additionalInfo}>
          <li>2017 / Class Project (Solo)</li>
          <li>Role: Interaction Designer, Design Researcher </li>
          <li>Supervisors: Edd Bagenal and Liam Hinshelwood</li>
          <li>Main Tools: Paper prototype, Processing</li>
          <li>Keywords: Design Process, Design Research</li>
        </ul>
      </header>
      
      {/*---- Work's Overview ----*/}
      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Overview</h3>
        <p>
          This class project aimed building an interventional design into a particular social issue by exploring design methods, and creating own design process. 
          The subject for me was fuel poverty, in which many people cannot get enough energy resource for their well being since its price is increasing. 
        </p>
        <p>
          After primary and secondary researches, I conceived that its radical issue may be lack of opportunities to be aware of limitation of fuel in modern lifestyle, which would increase the cost. 
          From this perspective, I experimented a conceptual idea with paper prototypes to intervene people’s daily energy consumption, and I realised that people always need energy urgently when they use it. 
        </p>
        <p>
          Finally, I improved the previous idea into alternative forms to intervene strongly people's daily activities. 
          In addition, I developed the total design process of this project as one design framework.
        </p>
      </section>

      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Design Process</h3>

        <h4 className={styles.workDetail_section_subTitle}>Research and Analysis</h4>
        <p>
          At the beginning, in order to find a suitable touchpoint, I started primary and secondary researches such as interviews, observation, bodystorming and recording and improving my own electrical consumption.
        </p>
        <p>
          Through these researches, I realised that one of the radical issues of fuel poverty may be related to lack of opportunities people can feel the limitation of energy resource in everyday life. 
          Additionally, modern designs focusing on functionality and efficiency could boost this issue.
          These facts unduly increase the energy demand and its price.
        </p>


        <h4 className={styles.workDetail_section_subTitle}>Ideation and Experiment</h4>
        <p>
          Subsequently, I started to focus on daily usage of electricity, specifically using plug sockets as very daily touchpoints with enery resource.
        </p>
        <p>
          I designed some conceptual ideas with paper prototypes and experimented one of them, putting dummy receipts informing electricity consumption, in order to make people aware of thier consumption in different daily situations.
        </p>
        
        <div className={styles.workDetail_images_wrapper}>
          <Image fileName={"methodsAndProcesses/process_paperPrototype_clockwork_01.jpg"} className={styles.workDetail_images_image} />
          <Image fileName={"methodsAndProcesses/process_paperPrototype_receipt_01.jpg"} className={styles.workDetail_images_image} />
        </div>
        <p className={styles.workDetail_images_caption}>Examples of the early paper prototypes to tell limitation of energy resource (clockwork and receipt)</p>

        <div className={styles.workDetail_images_wrapper}>
          <Image fileName={"methodsAndProcesses/process_paperPrototype_receipt_02.jpg"} className={styles.workDetail_images_image} />
          <Image fileName={"methodsAndProcesses/process_experiment_03_w.jpg"} className={styles.workDetail_images_image} />
        </div>
        <p className={styles.workDetail_images_caption}>The modified prototypes of recipt and the scene of experiment</p>


        <h4 className={styles.workDetail_section_subTitle}>Evaluation and Improvement</h4>
        <p>
          While experimenting, the people seemed to concentrate on using plug sockets quite strongly since they urgently needed electricity. 
        </p>
        <p>
          After the experiment, I developed the previous idea of receipts to alternative designs to intervene people's lifestyle more strongly by reconsidering about the form and timing. 
        </p>
        <div className={styles.workDetail_images_wrapper}>
          <Image fileName={"methodsAndProcesses/process_prototype_2ndReceipt_01.jpg"} className={styles.workDetail_images_image} />
          <Image fileName={"methodsAndProcesses/process_prototype_signage_01.jpg"} className={styles.workDetail_images_image} />
        </div>
        <p className={styles.workDetail_images_caption}>The developed ideas of receipt idea with questionnaire and digital signage.</p>



        <h4 className={styles.workDetail_section_subTitle}>Analysis of Total Design Process</h4>
        <p>
          At the end, I reviewed the total process of this project as one design framework and drew the design flow by employing a figure of light bulb.
        </p>
        <p>
          The outline of this framework is similar to Double Diamond, Design Council proposes [<a href="https://www.designcouncil.org.uk/news-opinion/what-framework-innovation-design-councils-evolved-double-diamond" target="_blank">1</a>]. I would like to improve this framework futher and apply it into future projects.
        </p>
        <Image fileName={"methodsAndProcesses/processesDiagram.png"} />

      </section>
    </article>
  </Layout>
)

export default MethodsAndProcesses
